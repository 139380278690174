// Note that antd is added per account type
:root {
  --color-primary: hsl(175, 75%, 40%);
  --color-primary-light: hsl(175, 75%, 50%);
  //--color-primary-fade: rgb(124, 196, 185);
  --color-primary-background: hsl(175, 75%, 31%);
  --color-primary-dark: hsl(175, 75%, 20%);

  --color-customer: hsl(175, 75%, 40%);
  --color-partner: hsl(18, 75%, 47%);
  --color-driver: hsl(127, 66%, 47%);
  --color-manager: hsl(254, 31%, 32%);

  --color-error: red;
  --color-success: green;

  --color-background: rgb(255, 255, 255);
  --color-text: rgb(75, 75, 75);
  --color-text-light: rgb(236, 236, 236);
  --color-shadow: rgb(70, 70, 70);
}

html,
body {
  background-color: var(--color-background);
  color: var(--color-text);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-col {
  margin-bottom: 0.5rem !important;
}
.ant-row.ant-form-item {
  margin-bottom: 0.5rem !important;
}
.ant-upload-select-picture-card {
  margin: 0 !important;
}
.ant-page-header.ant-page-header-ghost {
  padding-top: 0 !important;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 70px;
  width: 70px;
  margin: -70px 0 0 -35px;
  border: 8px solid #f1f1f1;
  border-radius: 50%;
  border-top: 8px solid var(--color-primary);
  animation: spin 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0 deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input {
  font-size: 16px !important;
}

button {
  background-color: initial;
  border: initial;
  box-shadow: initial;
}
/*
OLD COLOURS
$colour-main: rgb(74, 189, 172);
$colour-main-contrast: rgb(255, 255, 255);
$colour-main-dark:  rgb(217, 217, 217);

$colour-customer: rgb(74, 189, 172);
$colour-partner: rgb(247, 120, 46);
$colour-driver: rgb(247, 183, 51);
$colour-manager: rgb(0, 181, 90);

$colour-background: rgb(255, 255, 255);
$colour-background-darker: rgb(245, 245, 245);
$colour-background-dark: rgb(237, 237, 237);
$colour-border: rgb(201, 201, 201);
$colour-border-dark: rgb(127, 127, 127);
$colour-accent: rgb(255, 255, 255);
$colour-accent-dark: rgb(191, 191, 191);
$colour-text: rgb(75, 75, 75);
$colour-text-dark: rgb(0, 0, 0);
$colour-shadow: rgb(70, 70, 70);
$colour-icon: rgb(150, 150, 150);

$colour-error: red;
$colour-success: green;

$colour-enabled: rgb(55, 111, 0);
$colour-disabled: rgb(110, 110, 110);
*/

